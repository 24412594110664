<template lang="">
    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn shadow-sm noprint" data-wow-delay="0.1s">
      <a href="/" class="navbar-brand d-flex align-items-center px-4 px-lg-5">
          <h2 class="m-0 text-primary">
            <font-awesome-icon icon="hospital" class="me-2" />
            Demo
          </h2>
      </a>
      <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto p-4 p-lg-0">
              <router-link to="/" class="nav-item nav-link active">Mis certificados</router-link>
          </div>
          <router-link to="/certificate/new"  class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">
            Solicitar certificado 
            <font-awesome-icon icon="arrow-right" class="ms-2" />
          </router-link>
      </div>
    </nav>
</template>

<script>
export default {
}
</script>

<style lang="scss">
    .navbar {
        &.sticky-top {
            top: -100px;
            transition: .5s;
        }

        .dropdown-toggle::after {
            border: none;
            content: "\f107";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            vertical-align: middle;
            margin-left: 8px;
        }

        .navbar-nav {
            a.btn {
                height: 75px;
            }
            .nav-link {
                margin-right: 30px;
                padding: 25px 0;
                font-size: 15px;
                font-weight: 500;
                text-transform: uppercase;
                outline: none;
                color: var(--dark);

                &:hover, .active {
                color: var(--primary);
                }
            }
        }
  }

  @media (max-width: 991.98px) {
    .navbar {
        .navbar-nav {
            border-top: 1px solid #EEEEEE;

            .nav-link  {
                margin-right: 0;
                padding: 10px 0;
            }
        }
    }
  }

  @media (min-width: 992px) {
    .navbar {
        .nav-item {
            .dropdown-menu {
                display: block;
                border: none;
                margin-top: 0;
                top: 150%;
                opacity: 0;
                visibility: hidden;
                transition: .5s;
            }
            &:hover .dropdown-menu {
                top: 100%;
                visibility: visible;
                transition: .5s;
                opacity: 1;
            }
        }
      }
  }
</style>