<template>
    <div id="capture" class="onlyprint"></div>
    <section class="container noprint mt-5">
        <div class="row">
            <div class="col" v-if="certificate.printCounter > 0">
                <h2>El certificado ya fue impreso.</h2>
                <quote>Por medidas de seguridad solo se permite imprimir una vez el documento.</quote>
            </div>
            <div class="col-md-10 col-lg-8 mb-5" v-if="Object.keys(certificate).length && certificate.printCounter == 0">
                <div class="preview">
                    <div class="preview-content">
                        <p class="certificate-number">No. {{certificate.certificateNumber}}</p>
                        <p>{{certificate.addressedTo}}:</p>
                        <p>El infrascrito Secretario de la Junta Directiva, certifica que el Doctor:</p>
                        <p class="certificate-fullname">{{certificate.fullName}}</p>
                        <p>Se encuentra inscrito en este colegio profesional, con el colegiado número {{certificate.idNumber}}, desde el {{ formatDate(certificate.startDate) }} y en calidad de colegiado activo inclusive hasta {{ formatDate(certificate.endDate) }}.</p>
                        <p class="certificate-sign">Firma y sello</p>
                        <div class="certificate-qr">
                            <div class="certificate-qr-text me-1">
                                Validar certificado
                            </div>
                            <div class="certificate-qr-code">
                                <qrcode-vue :value="qr_url" :size="qr_size" level="Q" />
                            </div>
                        </div>
                    </div>
                    <div class="watermark" data-html2canvas-ignore="true">Vista previa</div>
                </div>
            </div>
            <div class="col-md-2 col-lg-4 d-flex align-items-center" v-if="Object.keys(certificate).length && certificate.printCounter == 0">
                <section class="text-center">
                <button type="button" class="btn btn-primary btn-lg" @click="print">
                    <font-awesome-icon icon="print" />
                    Imprimir
                </button>
                <p class="mt-4">Por medidas de seguridad solo se permite imprimir una vez el documento. Asegúrese de que la impresora este encendida y conectada al equipo.</p>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import formatDateMixin from '../mixins/formatDateMixin.js';
import QrcodeVue from 'qrcode.vue';
import html2canvas from 'html2canvas';

export default {
    name: "CertificatePrint",
    components: {
      QrcodeVue,
    },
    props: ["id"],
    data() {
        return {
            certificate: {},
            qr_url: "",
            qr_size: 70
        };
    },
    async mounted() { 
        const url = "/certificates/" + this.id ;      
        const { data } = await this.axios.get(url);
        this.certificate = data;
        this.qr_url = window.location.origin + "/certificate/" + this.id;
    },
    mixins: [formatDateMixin],
    methods: {
        async print() {
            const url = "/certificates/generate/" + this.id ;      
            const { data } = await this.axios.get(url);
            console.log(data);

            html2canvas(document.querySelector(".preview")).then(canvas => {
                const capture = document.querySelector("#capture");
                capture.innerHTML = '';
                capture.appendChild(canvas);
                window.print();
            });

            this.certificate.printCounter++;
        }
    }
}
</script>

<style lang="scss">
    @media print {
		.noprint {
			display: none !important;
		}
	}
    @media screen {
        .onlyprint {
            display: none !important;
        }
    }

    .preview {
        position: relative;
        background: #fff;
        width: 90%;
        max-width: 612px;
        height: 100%;
        max-height: 791px;
        margin: 20px auto;
        padding: 60px 40px;
        box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);

        &:after, &:before{
            content: '';
            position: absolute;
            left: auto;
            background:none;
            z-index: -1;
        }

        &:after{
            width: 90%;
            height: 10px;
            top: 30px;
            right:8px;
            -webkit-transform: rotate(-3deg);
            -moz-transform: rotate(-3deg);
            -o-transform: rotate(-3deg);
            -ms-transform: rotate(-3deg);
            transform: rotate(-3deg);
            -webkit-box-shadow: 0px -20px 36px 5px #295d92;
            -moz-box-shadow: 0px -20px 36px 5px #295d92;
            box-shadow: 0px -25px 35px 0px rgba(0,0,0,0.5);
        }

        &:before{
            width: 10px;
            height: 95%;
            top: 5px;
            right:18px;
            -webkit-transform: rotate(3deg);
            -moz-transform: rotate(3deg);
            -o-transform: rotate(3deg);
            -ms-transform: rotate(3deg);
            transform: rotate(3deg);
            -webkit-box-shadow: 20px 0px 25px 5px #295d92;
            -moz-box-shadow: 20px 0px 25px 5px #295d92;
            box-shadow: 22px 0px 35px 0px rgba(0,0,0,0.5);
        }

        .watermark {
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 250px;
            right: 0;
            z-index: 1;
            
            color: #0d745e;
            font-size: 75px;
            font-weight: 300;
            opacity: 0.15;
            transform: rotate(-45deg);
        }

        .preview-content {
            .certificate-number {
                text-align: right;
                margin-bottom: 40px;
                font-weight: bold;
                font-family: 'Times New Roman', Times, serif;
                font-size: 18px;
            }
            .certificate-fullname, .certificate-sign {
                margin: 30px 0;
                text-align: center;
                text-transform: uppercase;
            }
            .certificate-sign {
                margin-top: 80px;
            }
            .certificate-qr {
                border: 1px solid #295d92;
                border-radius: 5px;
                width: 160px;
                padding: 10px;
                display: flex;
                align-items: center;
                margin-left: auto;

                .certificate-qr-text {
                    font-size: 12px;
                }
            }
        }
    }
</style>