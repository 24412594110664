export default {
    methods: {
        formatDateTime(dateString) {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('es-GT', {dateStyle: 'long', timeStyle: "short"}).format(date);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('es-GT', {dateStyle: 'long'}).format(date);
        }
    }
}