import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/* Bootstrap */
import BootstrapVue3 from 'bootstrap-vue-3'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/bootstrap-custom.scss'

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = 'https://cert-staging.paginasingeniosas.com';

/* import font awesome icon component */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHospital, faArrowRight, faCalendarDays, faPrint, faCheckCircle, faCircleXmark, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faHospital, faArrowRight, faCalendarDays, faPrint, faCheckCircle, faCircleXmark, faUserDoctor)

/* FormKit */ 
import { plugin, defaultConfig } from '@formkit/vue'
import { es } from '@formkit/i18n'
import '@formkit/themes/genesis'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

/* App */
const app = createApp(App)
app.use(router)
app.use(BootstrapVue3)
app.use(VueAxios, axios)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(plugin, defaultConfig({
    locales: { es },
    locale: 'es'
}));
const options = {};
app.use(Toast, options);
app.mount('#app')
