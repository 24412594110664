<template lang="">
<section class="container mt-5" v-if="this.valid !== null">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-6">
                <h2>
                    Certificado No. {{certificate.certificateNumber}}
                    <font-awesome-icon icon="check-circle" class="text-success" v-if="this.valid" />
                    <font-awesome-icon icon="circle-xmark" class="text-danger" v-else />
                </h2>
            </div>
            <div class="col-md-2"></div>
        </div>
        <div class="row d-flex justify-content-center align-items-center" v-if="Object.keys(certificate).length">
            <div class="col-md-8">
                <p v-if="this.valid">Estado: Certificado válido </p>
                <p v-else>Estado: Certificado inválido </p>
                <p>Emitido a: {{certificate.fullName}}</p>
                <p>No. colegiado: {{certificate.idNumber}}</p>
                <p>Dirigido a: {{certificate.addressedTo}}</p>
                <p>Certificado emitido el {{ formatDateTime(certificate.emissionDate) }}.</p>
            </div>
        </div>
    </section>
</template>
<script>
import formatDateMixin from '../mixins/formatDateMixin.js';

export default {
    name: "CertificateValidate",
    props: ["id"],
    data() {
        return {
            certificate: {},
            valid: null
        };
    },
    mixins: [formatDateMixin],
    async mounted() { 
        const url = "/certificates/validate/" + this.id ;      
        const { data } = await this.axios.get(url);
        this.certificate = data.data;
        this.valid = data.success;
    },
}
</script>
<style lang="">
    
</style>