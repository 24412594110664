<template>
    <section class="container mt-5">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-sm-8 col-md-4 col-lg-6">
                <h2>Certificados</h2>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <router-link to="/certificate/new"  class="btn btn-primary rounded-0 d-block d-lg-none">
                    Solicitar certificado 
                    <font-awesome-icon icon="arrow-right" class="ms-2" />
                </router-link>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-8">
                <ul class="list list-inline">
                    <li v-for="certificate in certificates" :key="certificate.id" class="row">
                        <div class="col-xs-12 col-md-5 d-flex flex-row align-items-center">
                            <div class="ms-2">
                                <h6 class="mb-0">
                                    <router-link :to="`/certificate/${certificate.id}`">{{certificate.fullName}}</router-link>
                                </h6>
                                <div class="d-flex flex-row mt-1 text-black-50 date-time">
                                    <div>
                                        <font-awesome-icon icon="calendar-days" />
                                        <i class="fa fa-calendar-o"></i>
                                        <span class="ms-2">Emitido el {{ formatDateTime(certificate.emissionDate) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-md-4 d-flex flex-row my-2 my-sm-0">
                            <div class="ms-2">
                                <h6 class="mb-0" >
                                    <router-link :to="`/certificate/${certificate.id}`">
                                        <span class="text-secondary">Certificado No. {{certificate.certificateNumber}}</span>
                                    </router-link>
                                </h6>
                                <div class="d-flex flex-row mt-1 text-black-50 date-time">
                                    <div>
                                        <font-awesome-icon icon="user-doctor" />
                                        <span class="ms-2">Colegiado No. {{ certificate.idNumber }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-md-3 d-flex flex-row align-items-center justify-content-end">
                            <div class="d-flex flex-column me-2">
                                <router-link :to="`/print/${certificate.id}`" v-if="certificate.printCounter === 0" type="button" class="btn btn-secondary">
                                    <font-awesome-icon icon="print" />
                                    Imprimir
                                </router-link>
                                <small v-else class="text-small">El certificado ya fue impreso</small>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import formatDateMixin from '../mixins/formatDateMixin.js';

    export default {
        name: "MyCertificates",
        data() {
            return {
                certificates: [],
            };
        },
        mixins: [formatDateMixin],
        async mounted() {
            const { data } = await this.axios.get(
                "/certificates/?order_by=emissionDate&order_direction=desc"
            );
            this.certificates = data.rows;
        },
    }
</script>

<style lang="scss">
    .list li {
        list-style: none;
        padding: 10px;
        border: 1px solid #e3dada;
        margin-top: 12px;
        border-radius: 5px;
        background: #fff
    }
</style>