<template lang="">
    <section class="container mt-5">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-8 mb-5">
                <div v-if="this.status === 'success'" class="alert alert-success" role="alert">
                    El certificado fue solicitado correctamente
                </div>
                <div v-if="this.status === 'error'" class="alert alert-danger" role="alert">
                    {{this.errMsg}}
                </div>
                <h2>Solicitar certificado</h2>
                <p class="mb-4">Por favor ingrese la siguiente información para obtener su certificado.</p>
                <FormKit 
                    type="form"
                    submit-label="Solicitar certificado"
                    @submit="newCertificate"
                >
                    <div class="form-group">
                    <FormKit
                    type="number"
                    label="Número de certificado"
                    placeholder="ej. 23456"
                    name="certificateNumber"
                    validation="required|length:3,15"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />
                    </div>

                    <FormKit
                    type="text"
                    label="Nombre completo"
                    name="fullName"
                    placeholder="ej. Juan José Lopez"
                    validation="required"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />

                    <FormKit
                    type="number"
                    label="Número de colegiado"
                    name="idNumber"
                    placeholder="ej. 12345"
                    validation="required"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />
                    <FormKit
                    type="date"
                    label="Colegiado desde"
                    name="startDate"
                    validation="required"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />

                    <FormKit
                    type="date"
                    label="Colegiado vigente hasta"
                    value="today"
                    name="endDate"
                    validation="required"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />

                    <FormKit
                    type="text"
                    label="Dirigido a"
                    name="addressedTo"
                    placeholder="ej. Ministerio de Salud"
                    validation="required"
                    validation-visibility="dirty"
                    autocomplete="off"
                    />
                </FormKit>
            </div>
        </div>
    </section>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
    data() {
        return {
            status: "inactive",
            errMsg: ""
        }
    },
    mounted(){
        
    },
    methods: {
        async newCertificate(data) {
            this.status = "loading"
            try {
                const response = await this.axios.post (
                    "/certificates/",
                    data
                );
                if (response.status === 201) {
                    console.log(response);
                    this.status = "success"
                    const toast = useToast();
                    toast.success("El certificado fue solicitado correctamente", {
                        timeout: 5000
                    });
                    this.$router.push({path: '/'});
                } else {
                    this.status = "error"
                    this.errMsg = response.statusText;
                }
            } catch (errmsg) {
                this.status = "error"
                this.errMsg = errmsg;
            }
        }
    }
}
</script>

<style lang="scss">
    .formkit-wrapper {
        max-width: 100% !important;
    }
</style>