import { createWebHistory, createRouter } from "vue-router";
import MyCertificates from "@/views/MyCertificates.vue";
import CertificateRequest from "@/views/CertificateRequest.vue";
import CertificatePrint from "@/views/CertificatePrint.vue";
import CertificateValidate from "@/views/CertificateValidate.vue";

const routes = [
  {
    path: "/",
    name: "Certificados",
    component: MyCertificates,
  },
  {
    path: "/certificate/new",
    name: "Solicitar certificado",
    component: CertificateRequest,
  },
  {
    path: "/print/:id",
    name: "Imprimir certificado",
    component: CertificatePrint,
    props: true
  },
  {
    path: "/certificate/:id",
    name: "Validar certificado",
    component: CertificateValidate,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;