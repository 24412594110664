<template>
  <NavBar />

  <main class="container-fluid">
    <router-view />
  </main>
</template>

<script>
import NavBar from './components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style lang="scss">
</style>
